export default function Header() {
    return (
        <section
            id="home"
            className="page bg-background text-primary min-vh-100 d-flex align-items-center justify-content-center"
        >
            <div className="text-center">
                <h1 style={{ fontSize: "20vw" }}>BAM!</h1>
                <p style={{ fontSize: "4vw" }}>Mobile Disco For Any Occasion</p>
                <p style={{ fontSize: "4vw" }}>- Based in Bristol, UK -</p>
            </div>
        </section>
    );
}
