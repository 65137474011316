export const data = [
  {
    author: "Steve Lugg",
    date: "February 21st, 2022",
    content: "Music for my wife's 60th birthday was booked through the club it was at. Very easy to book the disco Andy phoned me to see what Music we liked. On the night of the party very good interaction with the people very good with requests. Will definitely book again.",
  },
  {
    author: "Steph",
    date: "October 15th, 2021",
    content: "Thanks so much for last night, we all had so much fun and my dad had the happiest night seeing all his friends and family that we haven’t been able to see in a long time due to covid. The music was a great mix, don’t be put off that there were only a handful of us dancing! Most of our family and friends love a dance but getting round and catching up with everyone took all night! The music was fab and you made sure that you played the requested songs straight away. Thank you for being part of it!",
  },
  {
    author: "Kat",
    date: "July 9th, 2018",
    content: "I thought you did a great job on the 7th July in keeping the party moving in spite of the challenges of the football kicking the running order around a bit and the music matched the mood perfectly. Tim thoroughly enjoyed the music and enjoyed a good boogie with his close friends left in the evening.",
  },
  {
    author: "Gareth Lock",
    date: "February 13th, 2018",
    content: "I booked BAM Mobile Disco for my mother-in-law’s 60th Birthday party on Saturday 3rd Feb 2018 and they were brilliant. From the beginning of the booking process to the party night the BAM team were always helpful and professional throughout. The night of the party was a great success with everyone loving the music and having a right good boogie. The BAM team had a raft of music to suit everyone’s needs and no one was left wanting for a favourite track. I would recommend BAM Mobile Disco (and they are prepared to travel) to anyone who is looking for a professional team who know their stuff. They will help make any party come to life"
  },
  {
    author: "Karen",
    date: "August 6th, 2017",
    content: "Absolutely brilliant, we couldn’t have wished for a better evening,for my 60th Birthday you played everything we asked for plus more, thank you once again, please hire them you will not be disappointed"
  },
  {
    author: "James",
    date: "May 2nd, 2013",
    content: "You and Mike did a brilliant job. My wife has not yet stopped talking about the party since Saturday. We both really enjoyed the choice of music, including some of our favourites. The light displays were quite a talking point as well. You did a very full set too. Thank you so much for a wonderful evening. I have already recommended you at work."
  },
];
