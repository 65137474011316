import { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { genericHashLink } from "react-router-hash-link";

const NavHashLink = genericHashLink(Nav.Link);

export default function Navigation() {
    const [activeKey, setActiveKey] = useState("#home");
    const pages = document.getElementsByClassName("page");

    window.addEventListener("scroll", (e) => {
        for (var i = pages.length - 1; i >= 0; i--) {
            var page = pages[i];
            var position = page.getBoundingClientRect().top;
            var visible = position <= window.innerHeight / 2;
            if (visible) {
                var hash = "#" + page.id;
                setActiveKey(hash);
                return;
            }
        }
    });

    return (
        <Navbar
            bg="background"
            variant="dark"
            fixed="top"
            expand="lg"
            collapseOnSelect={true}
        >
            <Container>
                <Navbar.Brand></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav
                        className="mr-auto"
                        activeKey={activeKey}
                        style={{ fontSize: 24 }}
                    >
                        <NavHashLink href="#home">Home</NavHashLink>
                        <NavHashLink href="#about">About</NavHashLink>
                        {/*<NavHashLink href="#gallery">Gallery</NavHashLink>*/}
                        <NavHashLink href="#reviews">Reviews</NavHashLink>
                        <NavHashLink href="#contact">Contact Us</NavHashLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
