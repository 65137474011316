import { Card, Container, Row, Col } from "react-bootstrap";

import { data } from "./data";

export default function Testimonials() {
    return (
        <section
            className="page bg-background text-primary min-vh-100"
            id="reviews"
        >
            <div className="align-items-center" id="reviews">
                <Container className="p-5">
                    <br />
                    <h1>Reviews</h1>
                    <Row xs={1} md={2} className="g-4">
                        {data.map((c) => {
                            return (
                                <Col key={c.date}>
                                    <Card
                                        bg="dark"
                                        className="text-secondary m-3"
                                    >
                                        <Card.Header>
                                            <Card.Title>{c.author}</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text>{c.content}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </div>
        </section>
    );
}
