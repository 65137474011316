import { Container } from "react-bootstrap";

export default function About() {
    return (
        <section
            className="bg-foreground text-primary min-vh-100 page align-items-center"
            id="about"
        >
            <Container className="p-5">
                <br />
                <br />
                <h1>About</h1>
                <div className="text-secondary" style={{ fontSize: 24 }}>
                    <article>
                        <p>
                            Fed up with DJs who wouldn’t play the music we
                            liked, we decided to set up our own mobile disco.
                            Initially specialising in rock music and doing gigs
                            for local motorcycle clubs, we gradually branched
                            out into more mainstream work.
                        </p>
                        <p>
                            Our ethos is to give the paying customer the music
                            they want, whether this is defined by musical genre,
                            decade, particular tracks or any other description.
                            A key part of our booking process is to ask you to
                            provide us with some idea of the music you want (and
                            anything specific you’d rather we didn’t play) for
                            your event as a guide.
                        </p>
                        <p>
                            Our excellent lighting rig is included in any price
                            quoted, with the options of strobe effect and
                            ‘smoke’ at no additional cost.
                        </p>
                        <p>
                            We have worked alongside live acts, are quite
                            prepared to travel, and our prices are very
                            competitive.
                        </p>
                        <p className="fst-italic">
                            Disclaimer – we are Bristol based and not connected
                            in any way with the business of the same name
                            operating in the Southampton area although we have
                            agreed not to take on work in Hampshire.
                        </p>
                    </article>
                </div>
                <br />
                <h2>Get in touch</h2>
                <p className="text-secondary" style={{ fontSize: 24 }}>
                    The best way to contact us is by{" "}
                    <a href="#contact">
                        filling out the form on our contacts page
                    </a>
                    , but you can also call either of us on the numbers below.
                    <br />
                    <br />
                    Andy: 07808 763961 <br /> Mike: 07757 503967
                </p>
            </Container>
        </section>
    );
}
