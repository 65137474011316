import "./App.css";
import Header from "./Header";
import About from "./About";
import useScrollSnap from "react-use-scroll-snap";
import { useRef } from "react";
import Contact from "./Contact";
import Testimonials from "./Testimonials";
import Navigation from "./Navigation";
import ParticleBackground from "./Particles";

function App() {
    const scrollRef = useRef(null);
    useScrollSnap({ ref: scrollRef, duration: 100, delay: 100 });

    return (
        <div>
            <Navigation />
            <section ref={scrollRef}>
                <Header />
                <About />
                {/*<Gallery />*/}
                <Testimonials />
                <Contact />
            </section>
        </div>
    );
}

export default App;
