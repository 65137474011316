import { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/types";

export default function Contact() {
    // Initial values empty
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState<E164Number>();
    const [message, setMessage] = useState("");
    const [date, setDate] = useState("");
    const [occasion, setOccasion] = useState("");

    // Hide / Show Errors
    const [showErrors, setShowErrors] = useState(false);

    // Error messages
    const [errorMsgs, setErrorMsgs] = useState([""]);

    // Mail Sent
    const [mailSent, setMailSent] = useState(false);

    // Clear or Reset Form
    function clearForm() {
        setName("");
        setEmail("");
        setNumber(undefined);
        setMessage("");
        setDate("");
        setOccasion("");
    }

    const [validated, setValidated] = useState(false);

    function isEmail(val: string): boolean {
        let regEmail = /^(?:\w+[-.])*\w+@(?:\w+[-.])*\w+\.\w+$/;
        return regEmail.test(val);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        // Set Empty on Submit
        setErrorMsgs([]);
        setMailSent(false);

        // Flag to check if email is valid or not
        let isValidEmail = false;

        if (!name) {
            setErrorMsgs((errorMsgs) => [
                ...errorMsgs,
                "Name is a required field.",
            ]);
        }

        if (!email) {
            setErrorMsgs((errorMsgs) => [
                ...errorMsgs,
                "Email is a required field.",
            ]);
        } else {
            if (!isEmail(email)) {
                setErrorMsgs((errorMsgs) => [
                    ...errorMsgs,
                    "Invalid email address.",
                ]);
            } else {
                isValidEmail = true;
            }
        }

        if (!message) {
            setErrorMsgs((errorMsgs) => [
                ...errorMsgs,
                "Message is a required field.",
            ]);
        }

        if (!name || !email || !isValidEmail || !message) {
            setShowErrors(true);
            return false;
        } else {
            setShowErrors(false);

            // Use API here
            setMailSent(true);
            console.log(name, email, message);

            var templateParams = {
                from_name: name,
                message: message,
                user_email: email,
                date: date,
                occasion: occasion,
                number: number,
            };

            emailjs
                .send(
                    "service_bam",
                    "template_bam_contact",
                    templateParams,
                    "I7SfVXgdwyfl5TAOE"
                )
                .then(
                    (response) => {
                        console.log("SUCCESS!", response.status, response.text);
                    },
                    (err) => {
                        console.log("FAILED...", err);
                    }
                );

            clearForm();
        }
    };

    if (mailSent) {
        return (
            <section className="bg-foreground text-primary min-vh-100">
                <div className="page align-items-center" id="contact">
                    <Container className="p-5">
                        <br />
                        <br />
                        <h1>Contact Us</h1>
                        <p className="text-secondary" style={{ fontSize: 24 }}>
                            Thank you for contacting BAM mobile disco.
                            <br /> Andy or Mike will be in touch shortly via
                            email or telephone to discuss your enquiry.
                        </p>
                    </Container>
                </div>
            </section>
        );
    }

    return (
        <section className="bg-foreground text-primary min-vh-100">
            <div className="page align-items-center" id="contact">
                <Container className="p-5">
                    <br />
                    <br />
                    <h1>Contact Us</h1>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        {showErrors
                            ? errorMsgs.map((msg, index) => {
                                  return (
                                      <div key={index} className="text-warning">
                                          {msg}
                                      </div>
                                  );
                              })
                            : ""}
                        <br />
                        <Form.Group controlId="formName">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter name"
                                required
                                value={name || ""}
                                onChange={(e: any) => setName(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide your name.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group controlId="formEmail">
                            <Form.Label>Your Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                required
                                value={email || ""}
                                onChange={(e: any) => setEmail(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                You must enter your email address or we won't be
                                able to contact you.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group controlId="formEmail">
                            <Form.Label>Your Phone Number</Form.Label>
                            <PhoneInput
                                defaultCountry="GB"
                                placeholder="Enter phone number"
                                value={number}
                                onChange={setNumber}
                            />
                        </Form.Group>
                        <br />
                        <Form.Group controlId="">
                            <Form.Label>Reason for contact</Form.Label>
                            <Form.Select
                                aria-label="Default select example"
                                value={occasion || ""}
                                onChange={(e: any) =>
                                    setOccasion(e.target.value)
                                }
                            >
                                <option value="other">
                                    Select an option...
                                </option>
                                <option value="Review">Leave a review</option>
                                <option value="Quotation">
                                    Request a quotation
                                </option>
                            </Form.Select>
                        </Form.Group>
                        <br />
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={6}
                                required
                                value={message || ""}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter details of your enquiry.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Container>
            </div>
        </section>
    );
}
